import communicationServices from '../../../../services/supplier/supplier-recall'
import { COMMUNICATION_TYPE, PROVIDER_EVENTLISTING_STATUS } from '../../../../misc/commons'
import { Provider_Disruption_AgGrid, AG_GridCustomizedTableHeaders_Key } from '../../../../misc/tableHeaders'

export default {
    data() {
        return {
            organization_id: null,
            rowModelType: 'serverSide',
            columnDefs: null,
            gridOptions: {
                onFilterChanged: this.onFilterChanged,
                onSortChanged: this.onSortChanged,
            },
            searchQuery: { pageNumber: 1, pageSize: 50, sort: null, colId: null },
            total: 0,
            items: [],
            item: {},
            title: {},
            status: '',
            gridApi: null,
            provider_status: PROVIDER_EVENTLISTING_STATUS,
            columnApi: null,
            search: {
                status: null,
                supplier: null,
                recallName: null
            },
            Originalitems: [],
            ChildRecall: [],
            ChildRecalltotal: 0,
            childHeader: [],
            SupplierContactlst: [],
            disruptionHeader: [
                {
                    label: 'Complete',
                    name: 'status_provider',
                    sort: true,
                    row_text_alignment: 'text-center',
                    column_text_alignment: 'text-center',
                    column_classes: 'customalign-2ercent',
                    row_classes: 'customalign-2percent wordbreakall',
                },
                {
                    label: 'Supplier',
                    name: 'supplier_org_name',
                    sort: true,
                    row_text_alignment: 'text-left',
                    column_text_alignment: 'text-left',
                    column_classes: 'customalign-10percent',
                    row_classes: 'customalign-10percent wordbreakall',
                },
                {
                    label: 'Advisory ID',
                    name: 'communicationUID',
                    sort: true,
                    row_text_alignment: 'text-left',
                    column_text_alignment: 'text-left',
                    column_classes: 'customalign-10percent',
                    row_classes: 'customalign-10percent wordbreakall',
                },
                {
                    label: 'Name',
                    name: 'communicationName',
                    sort: true,
                    row_text_alignment: 'text-left',
                    column_text_alignment: 'text-left',
                    column_classes: 'customalign-10percent',
                    row_classes: 'customalign-10percent wordbreakall',
                },
                {
                    label: 'Categories',
                    name: 'categories',
                    row_text_alignment: 'text-left',
                    column_text_alignment: 'text-left',
                    column_classes: 'customalign-10percent',
                    row_classes: 'customalign-10percent wordbreakall',
                    sort: true,
                },
                {
                    label: '# of Sites',
                    name: 'recall_sites',
                    sort: true,
                    row_text_alignment: 'text-left',
                    column_text_alignment: 'text-left',
                },
                {
                    label: 'Response Documents',
                    name: 'response_doc',
                    sort: true,
                    row_text_alignment: 'text-left',
                    column_text_alignment: 'text-left',
                    column_classes: 'customalign-5percent',
                    row_classes: 'customalign-5percent wordbreakall',
                },

                {
                    label: 'Source',
                    name: 'isProviderCreated',
                    sort: true,
                    row_text_alignment: 'text-center',
                    column_text_alignment: 'text-left',
                    column_classes: 'customalign-5percent',
                    row_classes: 'customalign-5percent wordbreakall',
                },

                {
                    label: 'Days Since Notified',
                    name: 'date_since_notified',
                    sort: true,
                    row_text_alignment: 'text-left',
                    column_text_alignment: 'text-left',
                },
                {
                    label: 'Event Status',
                    name: 'status',
                    sort: true,
                    row_text_alignment: 'text-center',
                    column_text_alignment: 'text-center',
                    column_classes: 'customalign-5percent',
                    row_classes: 'customalign-5percent wordbreakall',
                },
                {
                    label: '',
                    name: 'action',
                    row_text_alignment: 'text-center',
                    column_text_alignment: 'text-center',
                },
            ],
            header: [
                {
                    label: 'Complete',
                    name: 'status_provider',
                    sort: true,
                    row_text_alignment: 'text-center',
                    column_text_alignment: 'text-center',
                    column_classes: 'customalign-2ercent',
                    row_classes: 'customalign-2percent wordbreakall',
                },
                {
                    label: 'Supplier',
                    name: 'supplier_org_name',
                    sort: true,
                    row_text_alignment: 'text-left',
                    column_text_alignment: 'text-left',
                    column_classes: 'customalign-10percent',
                    row_classes: 'customalign-10percent wordbreakall',
                },
                {
                    label: 'Recall ID',
                    name: 'communicationUID',
                    sort: true,
                    row_text_alignment: 'text-left',
                    column_text_alignment: 'text-left',
                    column_classes: 'customalign-10percent',
                    row_classes: 'customalign-10percent wordbreakall',
                },
                {
                    label: 'Name',
                    name: 'communicationName',
                    sort: true,
                    row_text_alignment: 'text-left',
                    column_text_alignment: 'text-left',
                    column_classes: 'customalign-10percent',
                    row_classes: 'customalign-10percent wordbreakall',
                },
                {
                    label: 'Categories',
                    name: 'categories',
                    row_text_alignment: 'text-left',
                    column_text_alignment: 'text-left',
                    column_classes: 'customalign-10percent',
                    row_classes: 'customalign-10percent wordbreakall',
                    sort: true,
                },
                {
                    label: '# of Sites',
                    name: 'recall_sites',
                    sort: true,
                    row_text_alignment: 'text-left',
                    column_text_alignment: 'text-left',
                },
                {
                    label: 'Response Documents',
                    name: 'response_doc',
                    sort: true,
                    row_text_alignment: 'text-left',
                    column_text_alignment: 'text-left',
                    column_classes: 'customalign-5percent',
                    row_classes: 'customalign-5percent wordbreakall',
                },
                {
                    label: 'Affected Product',
                    name: 'affectedProduct',
                    sort: true,
                    row_text_alignment: 'text-center',
                    column_text_alignment: 'text-left',
                    column_classes: 'customalign-10percent',
                    row_classes: 'customalign-5percent wordbreakall',
                },
                {
                    label: 'Source',
                    name: 'isProviderCreated',
                    sort: true,
                    row_text_alignment: 'text-center',
                    column_text_alignment: 'text-left',
                    column_classes: 'customalign-5percent',
                    row_classes: 'customalign-5percent wordbreakall',
                },

                {
                    label: 'Days Since Notified',
                    name: 'date_since_notified',
                    sort: true,
                    row_text_alignment: 'text-left',
                    column_text_alignment: 'text-left',
                },
                {
                    label: 'Event Status',
                    name: 'status',
                    sort: true,
                    row_text_alignment: 'text-center',
                    column_text_alignment: 'text-center',
                    column_classes: 'customalign-5percent',
                    row_classes: 'customalign-5percent wordbreakall',
                },
                {
                    label: '',
                    name: 'action',
                    row_text_alignment: 'text-center',
                    column_text_alignment: 'text-center',
                },
            ],
            table_Connection_Data: this.$store.state.CustomizedHeaders.filter(s => s.userId == this.$store.state.user.id)[0],
        }
    },
    beforeMount() {
        this.columnDefs = Provider_Disruption_AgGrid
    },
    created() {
        this.rowModelType = 'serverSide'
        if (this.$store.state.lastedRouteNotAuth.fullPath != undefined && this._checkPermissions(['Supplier_ManageRecalls'])) {
            return this.$router.push({ path: this.$store.state.lastedRouteNotAuth.fullPath })
        }
    },
    methods: {
        saveState(params) {
            if(this.table_Connection_Data != undefined)
            {
                this.table_Connection_Data.Provider_Login.Disruption_Headers = params.columnApi.getColumnState()
                localStorage.setItem(AG_GridCustomizedTableHeaders_Key, JSON.stringify(this.$store.state.CustomizedHeaders))
            }
        },
        applyState(params) {
            var tableState = JSON.parse(localStorage.getItem(AG_GridCustomizedTableHeaders_Key))?.filter(s => s.userId == this.$store.state.user.id)[0]
            var columnState = tableState?.Provider_Login?.Disruption_Headers
            if (columnState) {
                params.columnApi.applyColumnState({ state: columnState, applyOrder: true })
            }
        },
        onCellClicked(params) {
            if (params.colDef.field == 'status') {
                this.addMoreRespClick(params.data)
                return
            }
            if (params.column.colId === 'actions') {
                if (params.event.target.dataset.action === 'view') {
                    this.viewRecall(params.data)
                }
                if (params.event.target.dataset.action === 'edit') {
                    this.editRecall(params.data)
                }
                if (params.event.target.dataset.action === 'delete') {
                    this.deleteCommunication(params.data)
                }
                return
            }else{
                this.viewRecall(params.data)
            }
        },
        onFilterChanged(event) {
            this.table_Connection_Data.Provider_Login.Disruption_Filters = event.api.getFilterModel()
            localStorage.setItem(AG_GridCustomizedTableHeaders_Key, JSON.stringify(this.$store.state.CustomizedHeaders))
            this.fetchData(event)
        },
        setFilterModel(params) {
            var tableState = JSON.parse(localStorage.getItem(AG_GridCustomizedTableHeaders_Key))?.filter(s => s.userId == this.$store.state.user.id)[0]
            var filterModel = tableState?.Provider_Login?.Disruption_Filters
            if (filterModel) {
                params.api.setFilterModel(filterModel)
            }
        },
        onSortChanged(event) {
            this.saveState(event)
            this.fetchData(event)
        },
        downloadRecallSummaryReport() {
            communicationServices.downloadRecallSummaryReport({
                status: 'All',
            })
        },
        downloadSupplierSummaryCSVReport() {
            communicationServices.downloadSupplierSummaryCSVReport({
                status: 'All',
            })
        },
        addMoreClick() {
            localStorage.setItem('disruptionMode', 'ADD')
            this.$router.push({ name: 'prov_Disruption_NewItem' })
        },
        viewRecall(item) {
            var Childrecall= this.Originalitems.filter(s=>s.communicationId==item.communicationId && s.recallSiteId>0 && s.recallSiteCount>1)
            if(Childrecall.length>0)
            {
                this.ChildRecall=Childrecall
                this.ChildRecalltotal= Childrecall.length
                this.childHeader = item.communicationType == COMMUNICATION_TYPE.PRODUCTDISRUPTION ? this.disruptionHeader : this.header
                this.title = {
                    type: item.communicationType == COMMUNICATION_TYPE.RECALL ? 'Recall' : 'Supply Advisory',
                }
                this.$root.$emit('bv::show::modal', 'modal-xl', item.communicationType)
            }
            else{
                this.$router.push({
                    name: item.communicationType == COMMUNICATION_TYPE.RECALL ?  'prov_RecallView' : 'prov_DisruptionView',
                    params: { id: item.communicationId, providerCommunicationId: item.providerCommunicationId,recallSiteCount: item.recallSiteCount},
                })
            }
        },
        viewChildRecall(item)
        {
            this.$router.push({
                name: item.communicationType == COMMUNICATION_TYPE.RECALL ?  'prov_RecallView' : 'prov_DisruptionView',
                params: { id: item.communicationId ,providerCommunicationId: item.providerCommunicationId,recallSiteCount: item.recallSiteCount},
            })
        },
        editRecall(item, e) {
            this.$router.push({ name: 'prov_Disruption_EditItem', params: { id: item.communicationId } })
            //prov_ProductDisruption_EditItem
        },
        deleteCommunication(item, e){
            this._showConfirm('Are you sure you want to delete the supply advisory, this cannot be undone?', {title: 'Delete Supply Advisory', variant: 'danger'}).then(confirm => {
                if (confirm) {
                    communicationServices.deleteCommunication(item.communicationId,COMMUNICATION_TYPE.PRODUCTDISRUPTION).then(resp => {
                        if (!resp.error) {
                            this._showToast('Supply advisory deleted!')
                            this.$router.go()
                        }
                    })
                }
            })
        },
        fetchData(params) {
            this.applyState(params)
            this.setFilterModel(params)
            let that = this
            that.gridApi = params.api
            that.columnApi = params.columnApi
            const datasource = {
                getRows(params) {
                    that.search.status = params.request?.filterModel?.status?.filter
                    that.search.recallName = params.request?.filterModel?.name?.filter
                    that.search.supplierRecallId = params.request?.filterModel?.communicationId?.filter
                    that.search.type = params.request?.filterModel?.type?.filter
                    that.search.classification = params.request?.filterModel?.classification?.filter
                    that.search.daysOpen = params.request?.filterModel?.days_open?.filter
                    that.search.communicationDate = params.request?.filterModel?.communicationDate?.dateFrom?.split(' ')[0]
                    that.search.nextexpectedupdate = params.request?.filterModel?.nextexpectedupdate?.dateFrom?.split(' ')[0]
                    that.search.notificationdate = params.request?.filterModel?.notificationdate?.dateFrom?.split(' ')[0]
                    that.search.anticipatedDuration = params.request?.filterModel?.anticipatedDuration?.filter
                    that.search.lastupdate = params.request?.filterModel?.notificationdate?.dateFrom?.split(' ')[0]
                    that.search.numberOfProviders = params.request?.filterModel?.number_of_providers?.filter
                    that.searchQuery.colId = params.request?.sortModel[0]?.colId
                    that.searchQuery.sort = params.request?.sortModel[0]?.sort
                    that.search.filterModel = that.gridApi.getFilterModel()
                    communicationServices.findProCommunications({
                        ...that.searchQuery,
                        sortField: params.request?.sortModel[0]?.colId,
                        sortDirection: params.request?.sortModel[0]?.sort,
                        ...that.search,
                        organizationId: that.organization_id,
                        communicationType: COMMUNICATION_TYPE.PRODUCTDISRUPTION
                    }).then(resp => {
                        if (!resp.error) {
                            const recallsarray = []
                            for (var i = 0; i < resp.data.d.c.length; i++) {
                                if(resp.data.d.c[i].userSiteName != undefined)
                                {
                                    if(resp.data.d.c[i].userSiteName.includes('Corporate'))
                                    {
                                        resp.data.d.c[i].isEdit = true
                                    }
                                    else
                                    {
                                        if(resp.data.d.c[i].recall_sites.includes('Corporate')){
                                            resp.data.d.c[i].isEdit = false
                                        }
                                        else
                                        {
                                            var usrarr = resp.data.d.c[i].userSiteName.split(',')
                                            var rclarr = resp.data.d.c[i].recall_sites.split(',')
                                            if(rclarr.length > usrarr.length)
                                            {
                                                resp.data.d.c[i].isEdit = false
                                            }
                                            else
                                            {
                                                var isEdit = true
                                                for (var p = 0; p < rclarr.length; p++)
                                                {
                                                    if(!resp.data.d.c[i].userSiteName.includes(rclarr[p].trimEnd().trimStart()))
                                                    {
                                                        isEdit = false
                                                    }
                                                }
                                                resp.data.d.c[i].isEdit = isEdit
                                            }
                                        }
                                    }
                                }
                                const TotalCopyCount=resp.data.d.c.filter(s=>s.communicationId==resp.data.d.c[i].communicationId && s.recallSiteId>0).length
                                resp.data.d.c[i].recallSiteCount=TotalCopyCount
                                var checkexist = recallsarray.filter(s => s.communicationId == resp.data.d.c[i].communicationId)
                                if (checkexist.length == 0) {

                                    recallsarray.push(resp.data.d.c[i])
                                }
                            }
                            that.Originalitems = resp.data.d.c.filter(s=>s.recallSiteId>0)
                            that.total = resp.data.d.t//resp.data.d.t
                            if(that.total == 0){
                                that.gridApi.showNoRowsOverlay()
                            }
                            else{
                                that.gridApi.hideOverlay()
                            }
                            return recallsarray
                        }
                    }).then(response => {
                        params.successCallback(response)
                    })
                        .catch(error => {
                            console.error(error)
                            params.failCallback()
                        })
                }
            }
            // register datasource with the grid
            params.api.setServerSideDatasource(datasource)
        }
    }
}
